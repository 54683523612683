body {


	position: relative;
	min-width: 360px;
	background-color: #f5f5f5;

	&.scrolled {
		@include media-breakpoint-up(md) {
			@include transition(padding easeOutSine 200ms);
			padding-top: 140px;
		}
	}

/*	&:not(.page_front) {
			
		@include media-breakpoint-up(md) {

			height: 100%;
			padding-bottom: 79px;

			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}

		}
		
	}*/


}
