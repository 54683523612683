.media_objects_wrapper {}


.media_object {

	@include media-breakpoint-up(md) {
		@include make-flex;
		.media_image,
		.media_content {
			position: relative;
			box-sizing: border-box;
			min-height: 1px;
		}
		.media_image { width: 40%; }
		.media_content { width: 60%; padding-left: 20px; }
	}

	@include media-breakpoint-up(lg) {
		.media_image { width: 35%; }
		.media_content { width: 65%; }
	}

	@include media-breakpoint-up(xl) {}


}

	.media_image {
		margin-bottom: 15px;
	}

	.media_content {
		> *:last-child { margin-bottom: 0; }
	}

		.media-title {
			margin-top: 0;
		}
