@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #fff;
  color: #333; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  padding: 0 30px; }

.flex_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em;
  box-sizing: border-box; }
  .flex_container [class*="col-"] {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 0 15px; }
  .flex_container.gutter-fix [class*="col-"] {
    padding-left: 0 !important; }
  @media (min-width: 576px) {
    .flex_container .col-sm-12 {
      width: 100%; }
    .flex_container .col-sm-11 {
      width: 91.66667%; }
    .flex_container .col-sm-10 {
      width: 83.33333%; }
    .flex_container .col-sm-9 {
      width: 75%; }
    .flex_container .col-sm-8 {
      width: 66.66667%; }
    .flex_container .col-sm-7 {
      width: 58.33333%; }
    .flex_container .col-sm-6 {
      width: 50%; }
    .flex_container .col-sm-5 {
      width: 41.66667%; }
    .flex_container .col-sm-4 {
      width: 33.33333%; }
    .flex_container .col-sm-3 {
      width: 25%; }
    .flex_container .col-sm-2 {
      width: 16.66667%; }
    .flex_container .col-sm-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) {
    .flex_container .col-md-12 {
      width: 100%; }
    .flex_container .col-md-11 {
      width: 91.66667%; }
    .flex_container .col-md-10 {
      width: 83.33333%; }
    .flex_container .col-md-9 {
      width: 75%; }
    .flex_container .col-md-8 {
      width: 66.66667%; }
    .flex_container .col-md-7 {
      width: 58.33333%; }
    .flex_container .col-md-6 {
      width: 50%; }
    .flex_container .col-md-5 {
      width: 41.66667%; }
    .flex_container .col-md-4 {
      width: 33.33333%; }
    .flex_container .col-md-3 {
      width: 25%; }
    .flex_container .col-md-2 {
      width: 16.66667%; }
    .flex_container .col-md-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .flex_container .col-md-break {
      width: 100%; } }
  @media (min-width: 992px) {
    .flex_container .col-lg-12 {
      width: 100%; }
    .flex_container .col-lg-11 {
      width: 91.66667%; }
    .flex_container .col-lg-10 {
      width: 83.33333%; }
    .flex_container .col-lg-9 {
      width: 75%; }
    .flex_container .col-lg-8 {
      width: 66.66667%; }
    .flex_container .col-lg-7 {
      width: 58.33333%; }
    .flex_container .col-lg-6 {
      width: 50%; }
    .flex_container .col-lg-5 {
      width: 41.66667%; }
    .flex_container .col-lg-4 {
      width: 33.33333%; }
    .flex_container .col-lg-3 {
      width: 25%; }
    .flex_container .col-lg-2 {
      width: 16.66667%; }
    .flex_container .col-lg-1 {
      width: 8.33333%; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .flex_container .col-lg-break {
      width: 100%; } }
  @media (min-width: 1200px) {
    .flex_container .col-xl-break {
      width: 100%; }
    .flex_container .col-xl-12 {
      width: 100%; }
    .flex_container .col-xl-11 {
      width: 91.66667%; }
    .flex_container .col-xl-10 {
      width: 83.33333%; }
    .flex_container .col-xl-9 {
      width: 75%; }
    .flex_container .col-xl-8 {
      width: 66.66667%; }
    .flex_container .col-xl-7 {
      width: 58.33333%; }
    .flex_container .col-xl-6 {
      width: 50%; }
    .flex_container .col-xl-5 {
      width: 41.66667%; }
    .flex_container .col-xl-4 {
      width: 33.33333%; }
    .flex_container .col-xl-3 {
      width: 25%; }
    .flex_container .col-xl-2 {
      width: 16.66667%; }
    .flex_container .col-xl-1 {
      width: 8.33333%; } }

@media (min-width: 768px) {
  .block_banner .row {
    align-items: center; }
  .block_banner .banner_branding {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .block_banner .banner_menu {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 992px) {
  .block_banner .banner_branding {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .block_banner .banner_menu {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 992px) {
  .block_jumbo .jumbo_marketing {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 30px; }
  .block_jumbo .jumbo_conversion {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 30px; } }

@media (min-width: 1200px) {
  .block_jumbo .jumbo_marketing {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .block_jumbo .jumbo_conversion {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 992px) {
  .block_content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .block_content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .block_content.full_width .main {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .block_content.flip .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    order: 2; }
  .block_content.flip .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    order: 1; } }

body {
  position: relative;
  min-width: 360px;
  background-color: #f5f5f5;
  /*	&:not(.page_front) {
			
		@include media-breakpoint-up(md) {

			height: 100%;
			padding-bottom: 79px;

			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}

		}
		
	}*/ }
  @media (min-width: 768px) {
    body.scrolled {
      -webkit-transition: padding easeOutSine 200ms;
      -moz-transition: padding easeOutSine 200ms;
      -ms-transition: padding easeOutSine 200ms;
      -o-transition: padding easeOutSine 200ms;
      transition: padding easeOutSine 200ms;
      padding-top: 140px; } }

html {
  line-height: 1.725em;
  -webkit-text-size-adjust: 100%; }

body {
  font-size: 16px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin: 1.75em 0 0.5em;
  font-family: "Alfa Slab One", "Times New Roman", Georgia, Times, serif;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.0375em; }
  h4 small, .h4 small, h3 small, .h3 small, h2 small, .h2 small, h1 small, .h1 small {
    display: block;
    font-weight: 400; }

h4, .h4 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400; }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 20px;
      font-size: 1.25rem; } }

h3, .h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400; }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 24px;
      font-size: 1.5rem; } }

h2, .h2 {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 400; }
  @media (min-width: 992px) {
    h2, .h2 {
      font-size: 32px;
      font-size: 2rem; } }

h1, .h1 {
  font-size: 36px;
  font-size: 2.25rem; }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 42px;
      font-size: 2.625rem; } }

p {
  margin: 0 0 1em; }
  p.lead {
    font-size: 120%;
    line-height: 1.53em; }

ul, ol {
  margin: 0;
  padding: 0; }

b, strong {
  font-weight: 600; }

address {
  margin-bottom: 1em;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 1em;
  font-size: 112.5%;
  line-height: 1.425em;
  border-left: 0.5rem solid; }
  blockquote > * {
    margin-bottom: 0.5em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #333; }
  blockquote.bq--primary {
    border-color: #00A99D; }
  blockquote.bq--secondary {
    border-color: #333; }
  blockquote.bq--highlight {
    border-color: #e16542; }
  blockquote.bq--accent {
    border-color: #a672b1; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #d39e00; }
  blockquote.bq--info {
    border-color: #6610f2; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

.bq--title {
  font-weight: 600;
  letter-spacing: -0.0275em;
  text-transform: uppercase; }
  .bq--text .bq--title {
    color: #333; }
  .bq--primary .bq--title {
    color: #00A99D; }
  .bq--secondary .bq--title {
    color: #333; }
  .bq--highlight .bq--title {
    color: #e16542; }
  .bq--accent .bq--title {
    color: #a672b1; }
  .bq--success .bq--title {
    color: #28a745; }
  .bq--danger .bq--title {
    color: #dc3545; }
  .bq--warning .bq--title {
    color: #d39e00; }
  .bq--info .bq--title {
    color: #6610f2; }

blockquote.bq-alt {
  margin-right: 5em;
  padding: 1.5em;
  border-radius: 6px;
  border: 4px solid #333;
  background-color: #fff; }
  blockquote.bq-alt.bq--primary {
    border-color: #00A99D; }
  blockquote.bq-alt.bq--secondary {
    border-color: #333; }
  blockquote.bq-alt.bq--highlight {
    border-color: #e16542; }
  blockquote.bq-alt.bq--accent {
    border-color: #a672b1; }
  blockquote.bq-alt.bq--success {
    border-color: #28a745; }
  blockquote.bq-alt.bq--danger {
    border-color: #dc3545; }
  blockquote.bq-alt.bq--warning {
    border-color: #ffc107; }
  blockquote.bq-alt.bq--info {
    border-color: #6610f2; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 2em auto;
  border: none;
  background-color: #333; }
  hr.hr-inset {
    margin: 2em auto;
    border: none;
    background-color: #bbb;
    box-shadow: 0 1px 0 0 #fff; }
  .content_text hr {
    margin: 1em 0 !important; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #e16542; }
  a:hover, a:focus {
    color: #e47758;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #737373; }

a.img-link {
  text-decoration: none; }

.phone {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  white-space: nowrap;
  cursor: default;
  color: #333; }
  .phone:hover, .phone:active, .phone:focus {
    text-decoration: none;
    color: #333;
    outline: none;
    cursor: default; }

.phone.mask {
  color: #333; }
  .phone.mask:hover {
    outline: none;
    color: #333; }

.list {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }
  .list li:not(:last-child) {
    margin-bottom: 0.25em; }

.list-inline, .list_icon, .list-hours, .menu, .list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list-inline li {
  display: inline-block; }

.list_icon li::before {
  display: inline-block;
  vertical-align: middle;
  width: 17px;
  height: 20px;
  margin-right: 8px;
  content: "";
  background-size: contain;
  background-position: left 2px;
  background-repeat: no-repeat; }

.icon_check-primary li::before {
  background-image: url("/_/images/icons/cm_primary.png"); }

.icon_check-secondary li::before {
  background-image: url("/_/images/icons/cm_secondary.png"); }

.icon_check-highlight li::before {
  background-image: url("/_/images/icons/cm_highlight.png"); }

.icon_check-accent li::before {
  background-image: url("/_/images/icons/cm_accent.png"); }

.list-hours li {
  margin-bottom: 0 !important; }

[class*="list-block"] {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  [class*="list-block"] li:not(:last-child) {
    margin-bottom: 0.25em; }
  [class*="list-block"] li {
    display: inline-block; }
  [class*="list-block"] li {
    -webkit-transform: skew(-15deg);
    -moz-transform: skew(-15deg);
    -o-transform: skew(-15deg);
    transform: skew(-15deg);
    margin-bottom: 0.5em !important;
    padding: 3px 10px;
    font-weight: 600;
    letter-spacing: 0.025em;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    background-color: #333;
    color: #fff; }
  [class*="list-block"] li:not(:last-child) {
    margin-right: 0.25em; }

.list-block--primary li {
  background-color: #0d9c92; }

.list-block--secondary li {
  background-color: #333; }

.list-block--highlight li {
  background-color: #e16542; }

.list-block--accent li {
  background-color: #a672b1; }

.list-block--success li {
  background-color: #28a745; }

.list-block--danger li {
  background-color: #dc3545; }

.list-block--warning li {
  background-color: #ffc107; }

.list-block--info li {
  background-color: #6610f2; }

@media (min-width: 576px) {
  .list-double-sm-up {
    display: flex;
    flex-wrap: wrap; }
    .list-double-sm-up > li {
      width: 50%; } }

@media (min-width: 768px) {
  .list-triple-md-up {
    display: flex;
    flex-wrap: wrap; }
    .list-triple-md-up > li {
      width: 33.33333%; } }

img {
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none; }

.img-main {
  margin: 0 0 15px;
  max-width: 360px; }

.img-coupon {
  max-width: 640px; }

.img-about {
  width: 150px; }

.img-auto {
  width: auto; }

.img-inline {
  display: inline; }

.img-thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right {
    clear: both;
    float: right;
    width: 45%;
    margin: 0 0 15px 20px; } }

@media (min-width: 768px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 20px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  width: 100%;
  max-width: none; }

.img--auto {
  max-width: auto; }
  @media (min-width: 576px) {
    .img--auto {
      width: auto;
      height: auto; } }

.img-captioned {
  padding: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img-captioned img {
    width: 100%;
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 360px;
    margin: 10px 5px 2px;
    font-size: 0.92rem;
    font-style: italic;
    line-height: 1.325em;
    color: #4d4d4d; }

.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block !important; }

.mt0 {
  margin-top: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mt15 {
  margin-top: 15px !important; }

.mt1 {
  margin-top: 1em !important; }

.mt2 {
  margin-top: 1em !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb1 {
  margin-bottom: 1em !important; }

.mb2 {
  margin-bottom: 2em !important; }

.notrfm {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.ls--sm {
  letter-spacing: -0.0375em; }

.ls--rg {
  letter-spacing: 0; }

.ls--lg {
  letter-spacing: 0.275em; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.bw0 {
  font-weight: 400 !important; }

.bw1 {
  font-weight: 600 !important; }

.bw2 {
  font-weight: 600 !important; }

.hw0 {
  font-weight: 400 !important; }

.hw1 {
  font-weight: 400 !important; }

.hw2 {
  font-weight: 400 !important; }

.color--text {
  color: #333 !important; }

.color--white {
  color: #fff !important; }

.color--primary {
  color: #00A99D !important; }

.color--secondary {
  color: #333 !important; }

.color--highlight {
  color: #e16542 !important; }

.color--link {
  color: #e16542 !important; }

.color--review {
  color: #fd7e14 !important; }

.color--success {
  color: #28a745 !important; }

.color--danger {
  color: #dc3545 !important; }

.color--warning {
  color: #ffc107 !important; }

.color--info {
  color: #6610f2 !important; }

.bg--text {
  background-color: #333 !important; }

.bg--white {
  background-color: #fff !important; }

.bg--primary {
  background-color: #00A99D !important; }

.bg--secondary {
  background-color: #333 !important; }

.bg--highlight {
  background-color: #e16542 !important; }

.bg--link {
  background-color: #e16542 !important; }

.bg--review {
  background-color: #fd7e14 !important; }

.bg--success {
  background-color: #28a745 !important; }

.bg--danger {
  background-color: #dc3545 !important; }

.bg--warning {
  background-color: #ffc107 !important; }

.bg--info {
  background-color: #6610f2 !important; }

.bg--transparent {
  background-color: transparent !important; }

.bg--body {
  background-color: #fff !important; }

.gdfancybg--text {
  background: #333;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #333; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #fff; }

.gdfancybg--primary {
  background: #00A99D;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #00A99D; }

.gdfancybg--secondary {
  background: #333;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #333; }

.gdfancybg--highlight {
  background: #e16542;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #e16542; }

.gdfancybg--review {
  background: #fd7e14;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #fd7e14; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #ffc107; }

.gdfancybg--info {
  background: #6610f2;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #6610f2; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed_responsive.embed_responsive_16by9 {
    padding-bottom: 56.25%; }
  .embed_responsive.embed_responsive_4by3 {
    padding-bottom: 75%; }
  .embed_responsive .embed_responsive_item, .embed_responsive embed, .embed_responsive iframe, .embed_responsive object, .embed_responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.btn {
  display: inline-block;
  padding: 12px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #cf4721;
  background-color: #e16542;
  color: #fff;
  -webkit-transform: scale(0.92);
  -moz-transform: scale(0.92);
  -o-transform: scale(0.92);
  transform: scale(0.92);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  line-height: 1em;
  cursor: pointer; }
  .btn:link, .btn:visited, .btn:active {
    text-decoration: none;
    border: 1px solid #cf4721;
    background-color: #e16542;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn:hover, .btn:focus {
    background-color: #ea9279;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn small {
    display: block;
    line-height: 1em; }
  .btn:focus {
    outline: 4px solid rgba(0, 0, 0, 0.25); }

.btn-text {
  display: inline-block;
  padding: 12px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #1a1a1a;
  background-color: #333;
  color: #fff;
  -webkit-transform: scale(0.92);
  -moz-transform: scale(0.92);
  -o-transform: scale(0.92);
  transform: scale(0.92);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-text:link, .btn-text:visited, .btn-text:active {
    text-decoration: none;
    border: 1px solid #1a1a1a;
    background-color: #333;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-text:hover, .btn-text:focus {
    background-color: #535353;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-link {
  display: inline-block;
  padding: 12px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #cf4721;
  background-color: #e16542;
  color: #fff;
  -webkit-transform: scale(0.92);
  -moz-transform: scale(0.92);
  -o-transform: scale(0.92);
  transform: scale(0.92);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-link:link, .btn-link:visited, .btn-link:active {
    text-decoration: none;
    border: 1px solid #cf4721;
    background-color: #e16542;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-link:hover, .btn-link:focus {
    background-color: #ea9279;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-primary {
  display: inline-block;
  padding: 12px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #00766e;
  background-color: #00A99D;
  color: #fff;
  -webkit-transform: scale(0.92);
  -moz-transform: scale(0.92);
  -o-transform: scale(0.92);
  transform: scale(0.92);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-primary:link, .btn-primary:visited, .btn-primary:active {
    text-decoration: none;
    border: 1px solid #00766e;
    background-color: #00A99D;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-primary:hover, .btn-primary:focus {
    background-color: #00e9d8;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-secondary {
  display: inline-block;
  padding: 12px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #1a1a1a;
  background-color: #333;
  color: #fff;
  -webkit-transform: scale(0.92);
  -moz-transform: scale(0.92);
  -o-transform: scale(0.92);
  transform: scale(0.92);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-secondary:link, .btn-secondary:visited, .btn-secondary:active {
    text-decoration: none;
    border: 1px solid #1a1a1a;
    background-color: #333;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-secondary:hover, .btn-secondary:focus {
    background-color: #535353;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-highlight {
  display: inline-block;
  padding: 12px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #cf4721;
  background-color: #e16542;
  color: #fff;
  -webkit-transform: scale(0.92);
  -moz-transform: scale(0.92);
  -o-transform: scale(0.92);
  transform: scale(0.92);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-highlight:link, .btn-highlight:visited, .btn-highlight:active {
    text-decoration: none;
    border: 1px solid #cf4721;
    background-color: #e16542;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-highlight:hover, .btn-highlight:focus {
    background-color: #ea9279;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-accent {
  display: inline-block;
  padding: 12px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #8e559b;
  background-color: #a672b1;
  color: #fff;
  -webkit-transform: scale(0.92);
  -moz-transform: scale(0.92);
  -o-transform: scale(0.92);
  transform: scale(0.92);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-accent:link, .btn-accent:visited, .btn-accent:active {
    text-decoration: none;
    border: 1px solid #8e559b;
    background-color: #a672b1;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-accent:hover, .btn-accent:focus {
    background-color: #b68bbf;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-success {
  display: inline-block;
  padding: 12px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #1e7e34;
  background-color: #28a745;
  color: #fff;
  -webkit-transform: scale(0.92);
  -moz-transform: scale(0.92);
  -o-transform: scale(0.92);
  transform: scale(0.92);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-success:link, .btn-success:visited, .btn-success:active {
    text-decoration: none;
    border: 1px solid #1e7e34;
    background-color: #28a745;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-success:hover, .btn-success:focus {
    background-color: #3ed160;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-danger {
  display: inline-block;
  padding: 12px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  color: #fff;
  -webkit-transform: scale(0.92);
  -moz-transform: scale(0.92);
  -o-transform: scale(0.92);
  transform: scale(0.92);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-danger:link, .btn-danger:visited, .btn-danger:active {
    text-decoration: none;
    border: 1px solid #bd2130;
    background-color: #dc3545;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-danger:hover, .btn-danger:focus {
    background-color: #e56b77;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-info {
  display: inline-block;
  padding: 12px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #510bc4;
  background-color: #6610f2;
  color: #fff;
  -webkit-transform: scale(0.92);
  -moz-transform: scale(0.92);
  -o-transform: scale(0.92);
  transform: scale(0.92);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-info:link, .btn-info:visited, .btn-info:active {
    text-decoration: none;
    border: 1px solid #510bc4;
    background-color: #6610f2;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-info:hover, .btn-info:focus {
    background-color: #8d4cf5;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-review {
  display: inline-block;
  padding: 12px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #dc6502;
  background-color: #fd7e14;
  color: #fff;
  -webkit-transform: scale(0.92);
  -moz-transform: scale(0.92);
  -o-transform: scale(0.92);
  transform: scale(0.92);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-review:link, .btn-review:visited, .btn-review:active {
    text-decoration: none;
    border: 1px solid #dc6502;
    background-color: #fd7e14;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-review:hover, .btn-review:focus {
    background-color: #fea153;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-warning {
  display: inline-block;
  padding: 12px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #d39e00;
  background-color: #ffc107;
  color: #fff;
  -webkit-transform: scale(0.92);
  -moz-transform: scale(0.92);
  -o-transform: scale(0.92);
  transform: scale(0.92);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-warning:link, .btn-warning:visited, .btn-warning:active {
    text-decoration: none;
    border: 1px solid #d39e00;
    background-color: #ffc107;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-warning:hover, .btn-warning:focus {
    background-color: #ffd147;
    color: #fff;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-block {
  display: block;
  text-align: center; }

.btn-icon {
  vertical-align: bottom; }
  .btn-icon img {
    display: inline-block;
    width: 22px;
    margin-right: 0.75em;
    vertical-align: middle; }
  .btn-icon.btn--lg img {
    width: 28px; }
  .btn-icon.btn--sm img {
    width: 18px; }

.btn--sm {
  padding: 8px 12px;
  font-size: 0.925rem; }

.btn--md {
  padding: 12px 15px;
  font-size: 1rem; }

.btn--lg {
  padding: 15px 20px;
  font-size: 1.075rem; }

.btn--wide {
  display: block;
  width: 100%; }

.btn_ghost {
  display: inline-block;
  padding: 12px 20px;
  font-size: 1.1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: -0.0325em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 60px;
  border: 3px solid #e16542;
  background-image: linear-gradient(to right, #e16542 50%, #fff 50%);
  background-position: top right;
  background-size: 200% 100%;
  -webkit-transform: skew(-15deg) scale(0.9);
  -moz-transform: skew(-15deg) scale(0.9);
  -o-transform: skew(-15deg) scale(0.9);
  transform: skew(-15deg) scale(0.9);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_ghost:hover {
    background-position: top left;
    color: #fff !important;
    -webkit-transform: skew(-15deg) scale(1);
    -moz-transform: skew(-15deg) scale(1);
    -o-transform: skew(-15deg) scale(1);
    transform: skew(-15deg) scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn_ghost.--primary {
    border-color: #00A99D;
    background-image: linear-gradient(to right, #00A99D 50%, #fff 50%);
    color: #00A99D; }
  .btn_ghost.--secondary {
    border-color: #333;
    background-image: linear-gradient(to right, #333 50%, #fff 50%);
    color: #333; }

.button_close {
  padding: 5px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  background-image: url("/_/images/icons/close.png");
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer; }
  .button_close:hover {
    border-color: #dc3545;
    background-color: #e4606d; }

.button_mobile {
  text-align: left; }

.mobile_toggle {
  width: 48px;
  height: 41px;
  padding: 10px 11px 9px;
  background-color: #333;
  border: none;
  border-radius: 4px;
  color: #fff; }
  .mobile_toggle:hover, .mobile_toggle:focus {
    outline: none;
    background-color: #4d4d4d;
    cursor: pointer; }
  .mobile_toggle b {
    display: none; }

.button-bars {
  display: inline-block;
  height: 100%;
  width: 26px; }

.icon-bar {
  -webkit-transition: transform ease-in-out 300ms;
  -moz-transition: transform ease-in-out 300ms;
  -ms-transition: transform ease-in-out 300ms;
  -o-transition: transform ease-in-out 300ms;
  transition: transform ease-in-out 300ms;
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 4px;
  background-color: #fff; }
  .icon-bar:nth-child(2) {
    margin: 5px 0; }

.canvas-slid .icon-bar:nth-child(2) {
  visibility: hidden; }

.canvas-slid .icon-bar:nth-child(1), .canvas-slid .icon-bar:nth-child(3) {
  -webkit-transition: transform ease-in-out 150ms;
  -moz-transition: transform ease-in-out 150ms;
  -ms-transition: transform ease-in-out 150ms;
  -o-transition: transform ease-in-out 150ms;
  transition: transform ease-in-out 150ms; }

.canvas-slid .icon-bar:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 9px; }

.canvas-slid .icon-bar:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -16px; }

.button_conversion {
  display: inline-block;
  padding: 15px 20px 15px 55px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  background-image: url("/_/images/icons/truck.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 55px auto;
  color: #fff;
  box-shadow: 0 22px 9px -20px rgba(0, 0, 0, 0.7);
  transform: scale(0.925);
  transition: none;
  transition-timing-function: linear;
  transition-duration: 300ms, 300ms, 200ms;
  transition-property: background-position, padding, transform, box-shadow;
  transition-delay: 0ms, 0ms, 0ms, 0ms; }
  .button_conversion:link, .button_conversion:visited, .button_conversion:active {
    text-decoration: none;
    border: 1px solid #bd2130;
    background-color: #dc3545;
    color: #fff; }
  .button_conversion:hover, .button_conversion:focus {
    padding: 15px 55px 15px 20px;
    background-color: #e04b59;
    background-position: right center;
    box-shadow: 0 22px 9px -20px transparent;
    color: #fff;
    transform: scale(1);
    transition-duration: 600ms, 300ms, 100ms, 300ms;
    transition-delay: 200ms, 100ms, 0ms, 300ms; }
  @media (min-width: 992px) {
    .button_conversion {
      font-size: 1.3rem; } }
  @media (min-width: 1200px) {
    .button_conversion {
      font-size: 1.5rem; } }

.call {
  line-height: 1em; }
  .call .phone {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1em;
    letter-spacing: -0.04em; }
    .call .phone.company-phone {
      color: #fff; }

.info_copyright {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3em;
  text-align: center; }
  .info_copyright a {
    font-weight: 600;
    line-height: 1em;
    text-decoration: none;
    color: #fff; }
  .info_copyright .copyright-list {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  @media (min-width: 768px) {
    .info_copyright .copyright-list {
      margin-left: 0;
      padding-left: 0;
      list-style-type: none;
      list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
      .info_copyright .copyright-list li {
        display: inline-block; }
      .info_copyright .copyright-list li:not(:last-child) {
        margin-right: 1em; } }

.form {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em; }

.form_group {
  margin: 0 0 8px;
  padding: 0; }
  .form_group.form_hp {
    display: none; }
  .form_group.form_clear {
    clear: both; }

label {
  display: block;
  margin: 0 0 1px 2px;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: -0.0275em; }

.form_control {
  display: block;
  width: 100%;
  margin: 0 0 2px;
  padding: 9px 7px;
  font-size: 1.1rem;
  line-height: 1em;
  border-radius: 2px;
  border: 1px solid #ddd;
  background-color: #fff;
  outline: none; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 150px;
  line-height: 1.275em; }

.form_error .form_control {
  border-radius: 2px 2px 0 0;
  border-color: #dc3545 !important; }

.error-message {
  margin: -5px 0 0;
  padding: 6px 4px 4px;
  font-size: 0.7em;
  font-weight: 600;
  line-height: 1em;
  text-transform: uppercase;
  background-color: #dc3545;
  color: #fff; }

@media (min-width: 768px) {
  .form_quote .form_left {
    float: left;
    width: 49.5%; }
  .form_quote .form_right {
    float: right;
    width: 49.5%; }
  .form_quote .form_street {
    margin-bottom: 12px; }
  .form_quote .form_city {
    float: left;
    width: 49.5%;
    margin-right: 1%; }
  .form_quote .form_state {
    float: left;
    width: 24.25%; }
  .form_quote .form_zip {
    float: right;
    width: 24.25%; } }

.branding-title,
.logo {
  display: block;
  overflow: visible;
  width: 240px;
  height: 85px;
  margin: 0 auto 15px;
  line-height: 0;
  text-align: center;
  text-indent: -9999px;
  background: url("/_/images/layout/logo.png") top center no-repeat;
  background-size: contain; }
  @media (min-width: 768px) {
    .branding-title,
    .logo {
      margin: 0; } }

.logo-footer {
  display: block;
  max-width: 150px;
  margin: 0 auto 10px; }

@media (min-width: 768px) {
  .media_object {
    display: flex;
    flex-wrap: wrap; }
    .media_object .media_image,
    .media_object .media_content {
      position: relative;
      box-sizing: border-box;
      min-height: 1px; }
    .media_object .media_image {
      width: 40%; }
    .media_object .media_content {
      width: 60%;
      padding-left: 20px; } }

@media (min-width: 992px) {
  .media_object .media_image {
    width: 35%; }
  .media_object .media_content {
    width: 65%; } }

.media_image {
  margin-bottom: 15px; }

.media_content > *:last-child {
  margin-bottom: 0; }

.media-title {
  margin-top: 0; }

.service_object {
  padding: 20px;
  align-items: center; }
  .service_object:not(:last-child) {
    padding-bottom: 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 1px 0 0 #fff; }
  .service_object .icon-secondary {
    fill: #333; }
  .service_object .icon-primary {
    fill: #00A99D; }

.service_image .services-icon {
  width: 150px;
  height: 150px;
  margin: 0; }

@media (min-width: 768px) {
  .service_image {
    text-align: center; } }

@media (min-width: 1200px) {
  .service_image .services-icon {
    width: 175px;
    height: 175px; } }

.menu {
  overflow: hidden;
  margin: 0;
  padding: 0; }

.menu_item {
  box-sizing: border-box;
  list-style-type: none;
  /*		&.active .menu_link {
			cursor: default;
			&:hover { cursor: default; }
		}*/ }
  .menu_item.open .dropdown_menu {
    display: block;
    height: auto; }
  .menu_item.open .caret {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.menu_link {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .menu_link:hover, .menu_link:focus {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    outline: none; }

.caret {
  display: inline-block;
  position: relative;
  vertical-align: baseline;
  width: 0.7em;
  height: 0.7em;
  background-image: url("/_/images/icons/caret-down-text.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center; }

.dropdown-toggle:hover .caret,
.open .dropdown-toggle .caret {
  background-image: url("/_/images/icons/caret-down-text.png"); }

.dropdown_menu {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: none;
  position: relative;
  z-index: 95000;
  margin: 0;
  padding: 1em;
  min-width: 280px;
  background-color: #fff; }
  .dropdown_menu .menu_item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    text-align: left; }
  .dropdown_menu .menu_link {
    padding-left: 10px; }
    .dropdown_menu .menu_link:hover, .dropdown_menu .menu_link:focus {
      outline: none; }
  @media (min-width: 768px) {
    .dropdown_menu {
      position: absolute; } }

.banner_menu .menu {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center; }
  .banner_menu .menu > .menu_item {
    display: inline-block;
    padding: 0 3px; }

.banner_menu .menu_link {
  display: block;
  min-width: 100px;
  padding: 13px 18px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.0325em;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  color: #333; }
  .banner_menu .menu_link:hover {
    background-color: #e16542;
    color: #fff; }
  .banner_menu .menu_link .caret {
    background-image: url("/_/images/icons/caret.png"); }

.banner_menu .dropdown_menu {
  padding: 10px;
  background-color: #00A99D;
  box-shadow: 0 6px 5px -4px rgba(0, 0, 0, 0.5); }
  .banner_menu .dropdown_menu .menu_item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.4); }
  .banner_menu .dropdown_menu .menu_item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.35); }
  .banner_menu .dropdown_menu .menu_item.active > .menu_link {
    background-color: #333;
    color: #fff; }
  .banner_menu .dropdown_menu .menu_link {
    padding: 15px 25px; }
    .banner_menu .dropdown_menu .menu_link:hover, .banner_menu .dropdown_menu .menu_link:focus {
      background-color: #00dccc; }

@media (min-width: 768px) {
  .banner_menu .menu > .menu_item {
    padding: 0; } }

.nav_mobile {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 90%;
  max-width: 320px;
  height: 100%;
  padding: 30px 15px;
  border-right: 3px solid #fff;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #00A99D;
  color: #fff; }
  .nav_mobile.in {
    display: block; }
  @media (min-width: 768px) {
    .nav_mobile {
      display: none !important; } }
  .nav_mobile h4 {
    font-size: 1.2em;
    font-weight: 400;
    text-transform: uppercase; }
  .nav_mobile .nav_menu > .menu_item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
  .nav_mobile .nav_menu > .menu_item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item.active .menu_link {
    background-color: #fff;
    color: #00A99D; }
    .nav_mobile .nav_menu > .menu_item.active .menu_link:hover, .nav_mobile .nav_menu > .menu_item.active .menu_link:focus {
      outline: none;
      background-color: #fff;
      color: #00A99D; }
  .nav_mobile .nav_menu > .menu_item.open > .menu_link {
    background-color: rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item.open .dropdown_menu {
    background-color: rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item > .menu_link {
    padding: 12px 10px;
    font-weight: 600;
    background-color: transparent;
    color: #fff; }
    .nav_mobile .nav_menu > .menu_item > .menu_link:hover, .nav_mobile .nav_menu > .menu_item > .menu_link:focus {
      outline: none;
      background-color: #333;
      color: #fff; }
  .nav_mobile .dropdown_menu {
    padding: 0; }
    .nav_mobile .dropdown_menu .menu_item {
      font-size: 0.925em; }
    .nav_mobile .dropdown_menu .menu_link {
      background-color: transparent;
      color: #fff; }
      .nav_mobile .dropdown_menu .menu_link:hover, .nav_mobile .dropdown_menu .menu_link:focus {
        outline: none;
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff; }
  .nav_mobile .mobile-toggle {
    font-size: 1.2em;
    color: #fff;
    text-decoration: none; }
    .nav_mobile .mobile-toggle img {
      display: inline-block;
      width: 0.8em;
      margin-right: 10px; }

.review {
  padding: 0 60px; }
  .review cite {
    display: block;
    margin-top: 1em;
    font-size: 15px;
    font-size: 0.9375rem;
    font-style: normal;
    line-height: 1em; }
    .review cite img {
      display: block;
      width: 120px;
      margin: 0 auto 5px; }
  .review .reivew-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide img {
  display: block;
  margin: 0; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/_/images/layout/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 95000;
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  bottom: 0;
  width: 55px;
  font-size: 0px;
  line-height: 0px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  opacity: 0; }
  .slick-prev:hover,
  .slick-next:hover {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    outline: none;
    opacity: 0.6; }

.slick-prev {
  left: 0;
  background-image: url("/_/images/icons/angle-left.svg"); }

.slick-next {
  right: 0;
  background-image: url("/_/images/icons/angle-right.svg"); }

.slider_jumbo .slick-prev,
.slider_jumbo .slick-next {
  display: none !important; }

.slider_jumbo .slide {
  border: 2px solid red;
  line-height: 0; }

.slider_jumbo .caption-title {
  margin: 0;
  line-height: 1em;
  color: #e16542; }
  .slider_jumbo .caption-title small {
    font-size: 70%;
    line-height: 1em;
    letter-spacing: 0.0225em;
    color: #333; }
  .slider_jumbo .caption-title span {
    display: block; }
  @media (min-width: 992px) {
    .slider_jumbo .caption-title {
      margin-bottom: 0; }
      .slider_jumbo .caption-title small {
        color: #fff; } }

.block {
  padding: 60px 0;
  outline: none !important;
  -webkit-transition: padding ease-in-out 300ms;
  -moz-transition: padding ease-in-out 300ms;
  -ms-transition: padding ease-in-out 300ms;
  -o-transition: padding ease-in-out 300ms;
  transition: padding ease-in-out 300ms; }

.block-title {
  margin-top: 0;
  color: #00A99D; }

@media (min-width: 768px) {
  .block_jump {
    padding-top: 120px; } }

.block_jumbo {
  text-align: center;
  border-bottom: 5px solid #ccc; }
  @media (min-width: 992px) {
    .block_jumbo {
      padding: 0;
      text-align: left;
      background-image: linear-gradient(to right, #333 40%, transparent 40%); }
      .block_jumbo .wrap {
        padding-top: 60px;
        padding-bottom: 60px; }
      .block_jumbo .jumbo_marketing {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: #333; }
        .block_jumbo .jumbo_marketing .slider_jumbo {
          max-width: 420px; } }

.marketing-title {
  line-height: 1em;
  color: #e16542; }
  .marketing-title small {
    font-size: 70%;
    line-height: 1em;
    letter-spacing: 0.0225em;
    color: #333; }
  .marketing-title span {
    display: block; }
  @media (min-width: 992px) {
    .marketing-title {
      margin-bottom: 0; }
      .marketing-title small {
        color: #fff; } }

.jumbo_conversion .lead {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.225em; }

.jumbo_conversion .list {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.275em; }
  .jumbo_conversion .list > li:not(:last-child) {
    margin-bottom: 10px; }

@media (min-width: 768px) {
  .jumbo_conversion .lead {
    font-size: 28px;
    font-size: 1.75rem; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .jumbo_conversion .lead {
    font-size: 24px;
    font-size: 1.5rem; } }

.block_welcome {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  border-bottom: 5px solid #ccc;
  background: linear-gradient(-45deg, transparent 15%, rgba(255, 255, 255, 0.3) 50%, transparent 85%), linear-gradient(-150deg, rgba(0, 0, 0, 0.2) 20%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.2) 80%), #00A99D;
  color: #fff; }
  .block_welcome .btn {
    font-size: 24px;
    font-size: 1.5rem; }

.block_services {
  padding-bottom: 0; }
  .block_services .row {
    justify-content: center; }
  @media (min-width: 992px) {
    .block_services .wrap {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 1200px) {
    .block_services .wrap {
      flex: 0 0 75%;
      max-width: 75%; } }

.block_contact {
  border-top: 1px solid #ccc;
  background-color: #fff; }
  @media (min-width: 992px) {
    .block_contact .contact_form {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .block_contact .contact_media {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; } }
  .block_contact .block-title {
    margin-bottom: 5px; }
  .block_contact .call {
    margin: 0 0 20px; }
    .block_contact .call a {
      font-size: 27px;
      text-decoration: none !important;
      color: #e16542 !important; }
  .block_contact .contact_media img {
    display: block;
    width: 100%;
    max-width: 480px;
    margin: 0 auto; }

.page_title {
  border-bottom: 5px solid #fff;
  background: linear-gradient(-45deg, transparent 15%, rgba(255, 255, 255, 0.3) 50%, transparent 85%), linear-gradient(-150deg, rgba(0, 0, 0, 0.2) 20%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.2) 80%), #00A99D;
  color: #fff; }
  .page_title .page-title {
    margin: 0; }
  @media (min-width: 992px) {
    .page_title .page-title {
      font-size: 42px;
      font-size: 2.625rem; } }

.block_banner {
  padding: 30px 0;
  border-bottom: 5px solid #ccc;
  background-color: #fff; }
  @media (min-width: 768px) {
    .block_banner {
      padding: 0; }
      .block_banner .banner_menu .menu {
        text-align: right; }
        .block_banner .banner_menu .menu .menu_link {
          padding: 60px 15px; }
      .block_banner.banner_sticky {
        position: fixed;
        top: 0;
        width: 100%;
        height: 65px;
        z-index: 99900;
        border-bottom-width: 1px; }
        .block_banner.banner_sticky .branding-title,
        .block_banner.banner_sticky .logo {
          -webkit-transition: all ease-in-out 300ms;
          -moz-transition: all ease-in-out 300ms;
          -ms-transition: all ease-in-out 300ms;
          -o-transition: all ease-in-out 300ms;
          transition: all ease-in-out 300ms;
          width: 150px;
          height: 53px; }
        .block_banner.banner_sticky .banner_menu .menu .menu_link {
          padding: 25px 15px; } }

@media (max-width: 991px) {
  .sidebar {
    padding-top: 2em; } }

.site_info {
  background-color: #333;
  color: #fff; }
