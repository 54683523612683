.block_contact {

	border-top: 1px solid #ccc;
	background-color: $white;

	@include media-breakpoint-up(lg) {
		.contact_form { @include make-col(7); }
		.contact_media { @include make-col(5); }
	}

	.block-title { margin-bottom: 5px; }

	.call {
		margin: 0 0 20px;
		a {
			font-size: 27px;
			text-decoration: none !important;
			color: $color-highlight !important;
		}
	}

	.contact_media img {
		display: block;
		width: 100%;
		max-width: 480px;
		margin: 0 auto;
	}

}