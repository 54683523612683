.block_welcome {

	@include font-size(24);
	font-weight: $body-weight-bold;
	text-align: center;
	border-bottom: 5px solid #ccc;
	background: 	linear-gradient( -45deg, transparent 15%, rgba($white, 0.3) 50%, transparent 85% ),
					linear-gradient( -150deg, rgba(0,0,0,0.2) 20%, transparent 45%, transparent 55%, rgba(0,0,0,0.2) 80% ),
					theme(primary, base);
	color: $white;

	.btn {
		@include font-size(24);
	}

}