.block_services {

	padding-bottom: 0;

	.row { justify-content: center; }

	@include media-breakpoint-up(lg) {
		.wrap { @include make-col(10); }
	}

	@include media-breakpoint-up(xl) {
		.wrap { @include make-col(9); }
	}

}