.banner_menu {
	
	.menu {
		display: block;
		width: 100%;
		margin: 0;
		padding: 0;
		text-align: center;

		> .menu_item {
			display: inline-block;
			padding: 0 3px;
		}
		
	}

	.menu_link {
		display: block;
		min-width: 100px;
		padding: 13px 18px;
		@include font-size(14);
		font-weight: $body-weight-bold;
		letter-spacing: -0.0325em;
		text-align: center;
		text-transform: uppercase;
		white-space: nowrap;
		color: theme(text, base);

		&:hover {
			background-color: theme(highlight, base);
			color: $white;
		}

		.caret { background-image: url("/_/images/icons/caret.png"); }

	}

	.dropdown_menu {
		padding: 10px;
		//border-top: 3px solid $white;
		background-color: theme(primary, base);
		box-shadow: 0 6px 5px -4px rgba(0,0,0,0.5);
		
		.menu_item {
			&:not(:first-child) { border-top: 1px solid rgba(255,255,255,0.4); }
			&:not(:last-child) { border-bottom: 1px solid rgba(0,0,0,0.35); }
			&.active {
				> .menu_link {
					background-color: theme(secondary, base);
					color: $white;
				}
			}
		}

		.menu_link {
			padding: 15px 25px;
			&:hover, &:focus {
				background-color: theme(primary, light);
			}
		}

	}

	@include media-breakpoint-up(md) {
		.menu > .menu_item {
			padding: 0;
		}
	}

}
