.block_banner {

	padding: 30px 0;
	border-bottom: 5px solid #ccc;
	background-color: $white;

	@include media-breakpoint-up(md) {
		padding: 0;

		.banner_menu .menu {
			text-align: right;
			.menu_link { padding: 60px 15px; }
		}

		&.banner_sticky {

			position: fixed;
			top: 0;
			width: 100%;
			height: 65px;
			z-index: 99900;
			border-bottom-width: 1px;

			.branding-title,
			.logo {
				@include transition(all ease-in-out 300ms);
				width: 150px;
				height: 53px;
			}

			.banner_menu .menu .menu_link {
				padding: 25px 15px;
			}

		}

	}

}

