.block {
	padding: 60px 0;
	outline: none !important;
	@include transition(padding ease-in-out 300ms);
}

.block_title {}

	.block-title {
		margin-top: 0;
		color: theme(primary, base);
	}

.block_content {}

.block_more {}


.block_jump {
	@include media-breakpoint-up(md) {
		padding-top: 120px;
	}
}