.service_objects_wrapper {}

.service_object {

	padding: 20px;
	align-items: center;

	&:not(:last-child) {
		padding-bottom: 40px;
		margin-bottom: 20px;
		border-bottom: 1px solid #ccc;
		box-shadow: 0 1px 0 0 #fff;
	}

	.icon-secondary { fill: theme(secondary, base); }
	.icon-primary { fill: theme(primary, base); }
	
}

.service_image {
	.services-icon {
		width: 150px;
		height: 150px;
		margin: 0;
	}
	@include media-breakpoint-up(md) {
		text-align: center;
	}
	@include media-breakpoint-up(xl) {
		.services-icon {
			width: 175px;
			height: 175px;
		}
	}
}