.page_title {

	border-bottom: 5px solid $white;
	background: 	linear-gradient( -45deg, transparent 15%, rgba($white, 0.3) 50%, transparent 85% ),
					linear-gradient( -150deg, rgba(0,0,0,0.2) 20%, transparent 45%, transparent 55%, rgba(0,0,0,0.2) 80% ),
					theme(primary, base);
	color: $white;

	.page-title {
		margin: 0;
	}

	@include media-breakpoint-up(md) {
	}
	
	@include media-breakpoint-up(lg) {
		.page-title {
			@include font-size(42);
		}
	}
	
}