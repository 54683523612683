.block_jumbo {

	text-align: center;
	border-bottom: 5px solid #ccc;

	@include media-breakpoint-up(lg) {
		padding: 0;
		text-align: left;
		background-image: linear-gradient( to right, theme(secondary, base) 40%, transparent 40% );
		.wrap {
			padding-top: 60px;
			padding-bottom: 60px;
		}
		.jumbo_marketing {
			@include make-flex();
			align-items: center;
			background-color: theme(secondary, base);
			.slider_jumbo { max-width: 420px; }
		}
	}

}

	.marketing-title {
		line-height: 1em;
		color: theme(highlight, base);
		
		small {
			font-size: 70%;
			line-height: 1em;
			letter-spacing: 0.0225em;
			color: theme(secondary, base);
		}

		span {
			display: block;
			//font-size: 120%;
			//line-height: 1em;
		}

		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			small { color: $white; }
		}
	}		

	.jumbo_conversion {

		.lead {
			@include font-size(22);
			line-height: 1.225em;
		}

		.list {
			@include font-size(18);
			line-height: 1.275em;
			> li:not(:last-child) { margin-bottom: 10px; }
		}

		@include media-breakpoint-up(md) {
			.lead { @include font-size(28); }
		}

		@include media-breakpoint-only(lg) {
			.lead { @include font-size(24); }
		}

	}