$placeholder-color: 		rgba($white, 0.9);
$placeholder-hover: 		rgba(black, 0.3);

.form_quote {

	@include media-breakpoint-up(md) {
		.form_left { float: left; width: 49.5%; }
		.form_right { float: right; width: 49.5%; }
		.form_street { margin-bottom: 12px; }
		.form_city { float: left; width: 49.5%; margin-right: 1%; }
		.form_state { float: left; width: 24.25%; }
		.form_zip { float: right; width: 24.25%; }
	}

}