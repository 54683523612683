@mixin btn-reg($bg: $color-link, $color: $white, $lighten: 12.5%) {
	display: inline-block;
    padding: 12px 15px;
    
    font-size: 1rem;
    font-family: $font-family-sans-serif;
    font-weight: $body-weight-bold;
    line-height: 1em;
    text-decoration: none;
    text-transform: uppercase;

	border-radius: 3px;
    border: 1px solid darken($bg, 10%);
    background-color: $bg;
    color: $color;
    @include transform(scale(0.92));
    @include transition(all ease-in-out 200ms);

	&:link, &:visited, &:active {
	    text-decoration: none;
	    border: 1px solid darken($bg, 10%);
	    background-color: $bg;
	    color: $color;
	    @include transition(all ease-in-out 200ms);
	}
    &:hover,
    &:focus {
        background-color: lighten($bg, $lighten);
        color: $color;
    	@include transform(scale(1));
        @include transition(all ease-in-out 200ms);
    }    
}


.btn {
	@include btn-reg;
    line-height: 1em;
	cursor: pointer;

	small { display: block; line-height: 1em }

	&:focus {
		outline: 4px solid rgba(0,0,0,0.25);
	}

}

	%btn_text, .btn-text { @include btn-reg($color-text) }
	%btn_link, .btn-link { @include btn-reg($color-link) }
	%btn_primary, .btn-primary { @include btn-reg($color-primary) }
	%btn_secondary, .btn-secondary { @include btn-reg($color-secondary) }
	%btn_highlight, .btn-highlight { @include btn-reg($color-highlight) }
	%btn_accent, .btn-accent { @include btn-reg($color-accent, $white, 7.5%) }
	%btn_success, .btn-success { @include btn-reg($color-success) }
	%btn_danger, .btn-danger { @include btn-reg($color-danger) }
	%btn_info, .btn-info { @include btn-reg($color-info) }
	%btn_review, .btn-review { @include btn-reg($color-review) }
	%btn_warning, .btn-warning { @include btn-reg($color-warning) }

	%btn-block, .btn-block { display: block; text-align: center; }

	.btn-icon {
		vertical-align: bottom;
		img {
			display: inline-block;
			width: 22px;
			margin-right: 0.75em;
			vertical-align: middle;
		}
		&.btn--lg img { width: 28px; }
		&.btn--sm img { width: 18px; }
	}

	%btn--sm, .btn--sm { padding: 8px 12px; font-size: 0.925rem; }
		@mixin btn--sm { padding: 8px 12px; font-size: 0.925rem; }

	%btn--md, .btn--md { padding: 12px 15px; font-size: 1rem; }
		@mixin btn--md { padding: 12px 15px; font-size: 1rem; }

	%btn--lg, .btn--lg { padding: 15px 20px; font-size: 1.075rem; }
		@mixin btn--lg { padding: 15px 20px; font-size: 1.075rem; }

	%btn--wide, .btn--wide { display: block; width: 100% }
		@mixin btn--wide { display: block; width: 100% }



.btn_ghost {

	display: inline-block;
    padding: 12px 20px;
    
    font-size: 1.1rem;
    font-family: $font-family-sans-serif;
    font-weight: $body-weight-bolder;
    line-height: 1em;
    letter-spacing: -0.0325em;
    text-decoration: none;
    text-transform: uppercase;

	border-radius: 60px;
	border: 3px solid $color-link;
	background-image: linear-gradient(to right, $color-link 50%, $white 50%);
	background-position: top right;
	background-size: 200% 100%;

	@include transform(skew(-15deg) scale(0.9));
    @include transition(all ease-in-out 200ms);

	&:hover {
		background-position: top left;
		color: $white !important;
		@include transform(skew(-15deg) scale(1));
		@include transition(all ease-in-out 200ms);
	}

	&.--primary {
		border-color: theme(primary, base);
		background-image: linear-gradient(to right, theme(primary,base) 50%, $white 50%);
		color: theme(primary, base);
	}

	&.--secondary {
		border-color: theme(secondary, base);
		background-image: linear-gradient(to right, theme(secondary, base) 50%, $white 50%);
		color: theme(secondary, base);
	}

}