.slider_jumbo {

	.slick-prev,
	.slick-next {
		display: none !important; }

	.slide {
		border: 2px solid red;
		line-height: 0;
	}

	.caption-title {
		margin: 0;
		line-height: 1em;
		color: theme(highlight, base);
		
		small {
			font-size: 70%;
			line-height: 1em;
			letter-spacing: 0.0225em;
			color: theme(secondary, base);
		}

		span {
			display: block;
			//font-size: 120%;
			//line-height: 1em;
		}

		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			small { color: $white; }
		}
	}	

}