// 150 x 53
// 180 x 64
// 200 x 71
// 220 x 78
// 240 x 85
// 270 x 96
// 360 x 128
// 480 x 171
// 640 x 228
// 744 x 265


.branding-title,
.logo {
	display: block;
	overflow: visible;
	width: 240px;
	height: 85px;
	margin: 0 auto 15px;
	line-height: 0;
	text-align: center;
	text-indent: -9999px;
	background: url("/_/images/layout/logo.png") top center no-repeat;
	background-size: contain;
	@include media-breakpoint-up(md) {
		margin: 0;
	}
}

	.logo-footer {
		display: block;
		max-width: 150px;
		margin: 0 auto 10px;
	}